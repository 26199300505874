@import "src/index";

.dropdown-list {
  box-shadow: 0 0 30px 0 rgba(51, 51, 51, 0.20);
  border-radius: 6px;
  padding: 0;

  .dropdown-item {
    @include text-sm();
    color: $text-primary;
    padding: 12px;

    .ant-select-item-option-content {
      @include text-sm();
      color: $text-primary;
      display: flex;
      align-items: center;

      .label {
        margin-left: 8px
      }
    }
  }
}

.dropdown-wrapper {
  min-width: 200px;
  height: 40px;

  .ant-select-selector {
    border: 1px solid $border-primary;
    align-items: center;
    height: 100% !important;

    .ant-select-selection-overflow {
      margin-right: 20px;
    }
  }

  .ant-select-selection-placeholder {
    color: $text-gray-100;
  }

  .ant-select-selection-search {
    width: 100%;
    margin: 0;
  }

  .ant-select-selection-search-input {
    height: 100% !important;
  }
}