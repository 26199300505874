@import "src/index";

.custom-table {
  .items-per-page {
    min-width: auto;
    height: 28px;

    .ant-select-selector {
      padding: 3px 12px !important;
    }
  }

  .pagination {
    display: flex;
    align-items: center;
    gap: 8px;

    li {
      min-width: 28px !important;
      height: 28px !important;
      margin: 0;
    }

    .ant-pagination-item {
      @include text-sm();
      border: 1px solid $border-primary;
      display: flex;
      align-items: center;
      width: fit-content;
      justify-content: center;
    }

    .ant-pagination-item-active {
      color: $text-white
    }
  }
}