.collapse-custom {
  .ant-collapse-header {
    //padding: 0 !important;
    align-items: center !important;
  }
  .ant-collapse-content {
    border-top: 1px solid #eee !important;

    .ant-collapse-content-box {
      padding: 0 !important
    }
  }
}
