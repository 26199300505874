@import "src/index";
.filter-wrap {
  padding: 8px 16px;
  border-radius: 6px;
  background-color: #fff;
  width: 100%;
  gap: 32px;

  .filter-wrap-item {
    display: flex;
    align-items: center;
    gap: 32px;

    .filter-item {
      display: flex;
      align-items: center;
      gap: 16px;
      flex: 1;
    }

    &:first-child {
      margin-bottom: 16px;
    }
  }
}
