@import "src/index";

.header-wrapper {
  height: auto;
  line-height: unset;

  .user-infor {
    .name {
      @include text-sm();
      font-weight: 500;
      color: $text-primary;
    }
  }
}
