@import "src/index";

.date-picker {
  padding: 8px 12px;

  .ant-picker-date-panel {
    .ant-picker-header {
      .ant-picker-header-view {
        font-weight: 500;
      }
    }
    .ant-picker-body {
      padding: 8px !important;

      .ant-picker-content {
        .ant-picker-cell-today {
          color: $button-primary;
          .ant-picker-cell-inner {
            &::before {
              border: none;
              content: '';
              left: 50%;
              top: unset;
              transform: translateX(-50%);
              width: 4px;
              height: 4px;
              background: $button-primary;
              border-radius: 50%;
            }
          }
        }

        .ant-picker-cell-selected {
          padding: 12px 0;
          background: $button-primary;
          border-radius: 8px;
          color: $text-white
        }
      }
    }
  }

  .ant-picker-footer {
    display: none;
  }
}