@import "src/index";

.custom-input {
  padding: 8px 12px;
}

.search-input {
  background: $text-white;

  input {
    background: $text-white;
  }
}