@import "src/index";

.sidebar-wrapper {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  .flower {
    position: absolute;
    bottom: 0;
  }

  //.menu-wrapper {
  //  height: 100%;

    .forlife-home {
      width: 100%;
      padding: 8px 16px 8px 24px;
      cursor: pointer;

      .title {
        color: $text-white;
      }
    }

    .forlife-project {
      width: 100%;
      padding: 32px 0 32px 24px;

      .project-title {
        @include text-base();
        color: $text-white;
        font-weight: 500;
        width: fit-content;
      }

      .project-aim {
        color: $text-white;
        font-size: 9px;
        line-height: 12px;
        width: fit-content;
      }
    }

    .custom-menu {
      background: transparent;

      .ant-menu-item-selected {
        border-left: 4px solid #fff;
      }
      .ant-menu-item{
        height: auto;
        padding: 24px 16px 24px 24px;

        .ant-menu-title-content {
          @include text-base();
          font-weight: 500;
          text-align: start;
          margin-left: 16px;
          width: max-content;
        }
      }
    }

    .custom-collapse-menu {
      .ant-menu-title-content {
        display: none;
        width: max-content;
      }
    }
  //}
}
