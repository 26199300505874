@import "src/index";

.custom-table {
  .table-class {
    .row-item {
      background: $border-primary;
      border-radius: 6px !important;
    }
  }
}
