@import "src/index";

.modal-wrapper {
  .ant-modal-content {
    padding: 0;

    .ant-modal-header {
      text-align: center;
      margin: 0;
      padding: 16px 20px;
      border-bottom: 1px solid $border-primary;

      .ant-modal-title {
        @include text-base();
        font-weight: 500 !important;
      }
    }

    .modal-footer-form, .modal-footer-confirm {
      width: 100%;
    }
  }
}